<template>
  <div class="page-content">
    <Breadcrumb/>

    <router-view></router-view>

  </div>
</template>

<script>
  import Breadcrumb from '../../templates/Breadcrumb/Breadcrumb.vue'
  import {HEARTYSAN_USER_TYPES} from "@/staticData/staticVariables";

  export default {
    name: "EtsyListingsModule",

    components: {
      Breadcrumb,
    },

    watch: {
      isSubUser() {
        return this.$router.push(this.$store.getters.GET_PATHS.notFound)
      },

      '$route'() {
        this.checkRoute()
      },

      getCurrentUser() {
        this.checkRoute()
      },
    },

    data() {
      return {

      }
    },

    mounted() {

      if (this.isSubUser) {
        return this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      if (this.getCurrentUser) {
        this.checkRoute()
      }
    },

    methods: {
      checkRoute() {
        if (this.isAdmin) return
        if (!this.getCurrentUser) return

        let userHeartisanType = this.getCurrentUser?.hearty_user_type?.id

        switch (userHeartisanType) {
          case HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id:
            return true
          default:
            return this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }

      },
    }
  }
</script>

<style lang="scss">

</style>
