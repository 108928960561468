<template>
  <div class="page-content">
    <Breadcrumb/>

    <router-view></router-view>

  </div>
</template>

<script>
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";

  export default {
    name: "ProductsModule",

    components:{
      Breadcrumb,
    },

    data(){
      return {

      }
    },

  }
</script>

<style scoped>

</style>
